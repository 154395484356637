import $ from "jquery";

window.careNavigationModalLogic = (function () {
  const elementIdentifiers = {
    DEA_LIST: '#dea_list',
    NPI_LIST: '#npi_list',
    CARE_NAVIGATION_MODAL: '#care_navigation_modal',
    FAIL_VALIDATION_MESSAGE: '#validation-fail-text',
    ENABLE_CARE_NAVIGATION_BUTTON_CHECKBOX: '#enable_care_navigation_button'
  }

  function documentRenderedHandler () {
    $(elementIdentifiers.DEA_LIST).select2({
      createTag: function (params) {
        const term = $.trim(params.term).toUpperCase();

        if (term === '') { return null; }

        return {
          id: term,
          text: term,
          newTag: true
        }
      },
      tags: true,
      tokenSeparators: [',', ' ', '\n'],
      placeholder: "Add DEA(s)"
    });

    $(elementIdentifiers.NPI_LIST).select2({
      tags: true,
      tokenSeparators: [',', ' ', '\n'],
      placeholder: "Add NPI(s)"
    });

    function copyButtonHandler (e) {
      let selectedItems = $(e.currentTarget.parentElement.querySelector('select')).select2('data');
      let result = selectedItems.reduce((acc, element) => {
        return acc.text += ', ' + element.text;
      });

      navigator.clipboard.writeText(result);

      e.preventDefault();
    }

    $('#care_navigation_modal_body .copy-button').on('click', copyButtonHandler);
    function hideModalHandler(e) {
      const dea_list = e.currentTarget.querySelector(elementIdentifiers.DEA_LIST);
      const npi_list = e.currentTarget.querySelector(elementIdentifiers.NPI_LIST);

      [ $(dea_list), $(npi_list) ].forEach((list) => list.find('option').prop('selected', true));

      resetList(dea_list);
      resetList(npi_list);

      $(elementIdentifiers.FAIL_VALIDATION_MESSAGE).hide();
    }

    $(elementIdentifiers.CARE_NAVIGATION_MODAL).on('hide.bs.modal', hideModalHandler);
  }

  $(document).ready(documentRenderedHandler);

  function alterVisualElementStyle(optionRef, color, itemString) {
    let selectRef = optionRef.parentElement;
    let visualGroup = selectRef.nextSibling;

    let ulRef = $('ul', visualGroup)[0];
    let listItems = $('li', ulRef);

    for (const listItem of listItems) {
      let text = listItem.childNodes[1];
      if (text.textContent === itemString) {
        listItem.style.backgroundColor = color;
      }
    }
  }

  function postDataToServer(selectedNpiItems, selectedDeaItems) {
    let deasUrlFormEncoded = '';
    let npisUrlFormEncoded = '';

    for (const selectedDeaItem of selectedDeaItems) {
      deasUrlFormEncoded += '&dea_list[]=' + selectedDeaItem.text;
    }

    for (const selectedNpiItem of selectedNpiItems) {
      npisUrlFormEncoded += "&npi_list[]=" + selectedNpiItem.text;
    }

    let id = window.care_navigation_modal_metadata.licensee_id;

    let xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      if (xhr.readyState === 4 && xhr.status === 204) {
        // Force reload to refresh the page ERB template.
        // There is text on the service configuration page underneath the modal
        // that populates data from the server side. A simple reload updates this, instead of
        // writing some JavaScript and changing the return of the endpoint to return results.
        location.reload(true);
      } else {
        $(elementIdentifiers.FAIL_VALIDATION_MESSAGE).
          text(xhr.responseText).
          show();
      }
    });

    xhr.open(
      "POST",
      window.location.protocol + "//" + window.location.host + "/" + "licensees/" + id + "/care_navigation_settings");

    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

    const authenticity_parameter_name = $('meta[name=csrf-param]').attr('content')
    const authenticity_token = $('meta[name=csrf-token]').attr('content');
    const csrfToken = authenticity_parameter_name + '=' + encodeURIComponent(authenticity_token);

    const body = '' +
      csrfToken +
      deasUrlFormEncoded +
      npisUrlFormEncoded;

    xhr.send(body);
  }

  function submitHandler() {
    const dea_list = document.querySelector(elementIdentifiers.DEA_LIST),
      npi_list = document.querySelector(elementIdentifiers.NPI_LIST);

    const selectedDeaItems = $(dea_list).select2('data'),
      selectedNpiItems = $(npi_list).select2('data');

    let hasValidationError = false;

    function validateData(validator, item) {
      if (!validator(item)) {
        alterVisualElementStyle(item.element, 'pink', item.text);
        hasValidationError = true;
      } else {
        alterVisualElementStyle(item.element, '#e4e4e4', item.text);
      }
    }

    selectedNpiItems.forEach(validateData.bind(this, validateNpi));
    selectedDeaItems.forEach(validateData.bind(this, validateDea));

    if (!hasValidationError) {
      $(elementIdentifiers.FAIL_VALIDATION_MESSAGE).hide();
    } else {
      $(elementIdentifiers.FAIL_VALIDATION_MESSAGE).show();
      return;
    }

    const enableCareNavChecked =
      $(elementIdentifiers.ENABLE_CARE_NAVIGATION_BUTTON_CHECKBOX)[0].checked;

    postDataToServer(selectedNpiItems, selectedDeaItems, enableCareNavChecked);
  }

  function validateNpi(npiItem) {
    return !!npiItem.text.match("^[0-9]{10}$");
  }

  function validateDea(deaItem) {
    deaItem = deaItem.text;
    let dea_regular_expression = "[A-z]{1}[A-z9]{1}[0-9]{7}$";
    let dea_regular_expression_with_intern = "[A-z]{1}[A-z9]{1}[0-9]{7}[-][A-z\\d]*";
    if (deaItem.match(dea_regular_expression) || deaItem.match(dea_regular_expression_with_intern)) {
      const result =
        parseInt(deaItem[2], 10) +
        parseInt(deaItem[4], 10) +
        parseInt(deaItem[6], 10) +
        ((parseInt(deaItem[3], 10) +
          parseInt(deaItem[5], 10) +
          parseInt(deaItem[7], 10)) * 2);

      return (result % 10 === parseInt(deaItem[8], 10));
    } else {
      return false;
    }
  }

  function resetList(list) {
    var startingSelectedItems = $(list).select2('data');
    var selectedItems = [];

    startingSelectedItems.forEach(pushPreselected);

    $(list).empty();

    selectedItems.forEach(reformItem);

    function pushPreselected(item) {
      if (item.title === 'preselected') {
        selectedItems.push(item);
      }
    }

    function reformItem(item) {
      $('<option>',
        {
          title: 'preselected',
          id: item.text,
          text: item.text,
          selected: "selected"
        }).select2({
        dropdownParent: $(item.id)
      }).appendTo(list);
    }
  }

  return {
    "updateCareNavigationSettings": submitHandler
  };
}).call(window);
